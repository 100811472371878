import React, { memo, useMemo } from 'react';
import { 
  Box, 
  Card, 
  Grid, 
  Link, 
  Stack, 
  Divider, 
  CardHeader, 
  Typography, 
  CardContent, 
} from '@mui/material';
import { IOrder } from '../interfaces/IOrder'
import { IDeliveryProduct } from '../interfaces/IDeliveryProduct';
import { IDelivery } from '../interfaces/IDelivery';
import { formatDate } from '../helper/formatDate';
import DeliveryProductItem from './DeliveryProductItem';
import { useNavigate } from 'react-router-dom';

interface IOrderItem {
  order: IOrder;
}

const OrderItem = memo(({
  order,
}: IOrderItem) => {
  const navigate = useNavigate();

  const getDeliveryProducts = () => {
    return order.deliveries.reduce((
      acc: IDeliveryProduct[], 
      curr: IDelivery,
    ) => [...acc, ...curr.products], []);
  };

  const createdDate = useMemo(() => formatDate(order.createdAt), [order]);

  const deliveryProducts: IDeliveryProduct[] = useMemo(() => getDeliveryProducts(), [order]);

  return (
    <Card>
      <CardHeader subheader={
        <Grid 
          container 
          spacing={2} 
          justifyContent="space-between"
        >
          <Grid item xs={6} container spacing={2}>
            <Grid item>
              <Typography variant='body1' color="text.primary">
                {`No. orden #${order.id}`}
              </Typography>
            </Grid>
            <Grid item>
              <Link
                variant='body2'
                component="button"
                underline="none"
                onClick={() => navigate(`/pays/${order.id}`)}
              >
                Ver detalles
              </Link>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant='body1'>
              {createdDate}
            </Typography>
          </Grid>
        </Grid>
      } />
      <CardContent>
        <Stack spacing={2}>
          {
            deliveryProducts.map((deliveryProduct, index) => (
              <Box
                key={`dp=${order.id}-${deliveryProduct.product.id}`}
              >
                <DeliveryProductItem 
                  deliveryProduct={deliveryProduct} 
                />
                {
                  deliveryProducts.length !== index + 1 ? (
                    <Divider light />
                  ) : null
                }
              </Box>
            ))
          }
        </Stack>
      </CardContent>
    </Card>
  );
});

export default OrderItem;
