import React from "react";
import {
  Typography,
  Box,
  Container,
} from "@mui/material";
//import srcQr from "../images/qr.png"
import Videos from "../components/Videos";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";

const AccountContainer = () => {

  const user = useSelector((state: any) => state.user);
  console.log({ user });

  return (
    <Container maxWidth="sm">
      <Box>
        <Typography className="title" align="center">
          Mi cuenta
        </Typography>

        {user ?
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "225px" }}
              value={user.email}
              viewBox={`0 0 256 256`}
            /> 
          </div>          
          : null
        }
        
        <Typography variant="caption" gutterBottom>
          Nombre
        </Typography>
        <Typography variant="body1" gutterBottom>
          {user.name}
        </Typography>
        <Typography variant="caption" gutterBottom>
          Email
        </Typography>
        <Typography variant="body1" gutterBottom>
          {user.email}
        </Typography>
      </Box>

      <Videos />
    </Container>
  );
};

export default AccountContainer;
