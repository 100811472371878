import React from "react";
import { Typography, Stack, Box, Button, Container, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import srcWoodmembership from "../images/woodmembership.png";

const WoodMemberContainer = () => {
    return (<Container maxWidth="sm">
        <Box>
            <Typography align="center" className="title">Wood</Typography>
            <img src={srcWoodmembership} width="100%" />

            <Typography className="paragraph text-red">
                Si buscas una buena relación calidad-precio, ¡de aquí eres!
            </Typography>
            
            <Typography className="paragraph">
                Con esta membresía tienes acceso al Grilling Concierge de lunes a viernes, de las 9:00
                a las 17:00 horas. Podrás disfrutar de samplings de productos y acceso a preventas,
                invitación a eventos, productos de selección Grado 12, promociones en comercios
                participantes y kits de recetas exclusivas.
            </Typography>


            <Typography className="paragraph">
                Además, tendrás derecho a un 10% de descuento en cursos y un 5% de descuento en
                productos Royal Reserve.
            </Typography>

            <Typography className="paragraph">
                También recibirás $100.00 de descuento por cada cliente referido que adquiera una
                membresía.
            </Typography>

            <Typography className="paragraph">
                Todo por un costo anual de $2,388.00.
            </Typography>
            <Typography className="paragraph">
                Recibe 10 membresías, para compartir con amigos y familiares, por $23,880.00.
            </Typography >


        </Box>
    </Container>)
}
export default WoodMemberContainer;
