import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Config from "../config";
import ETransactionStatus from "../enums/ETransactionStatus";
import EPaymentService from "../enums/EPaymentService";
import { Box, Button, CircularProgress, Container, Typography } from "@mui/material";
import IGetTransaction from "../interfaces/IGetTransaction";
import axios from "axios";
import { green, red } from "@mui/material/colors";
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import cartActions from "../redux/cart/actions";
import { useDispatch } from "react-redux";

const TransactionStatusPage = () => {
  const whId = Config.apiWarehouseId;
  const { Null, Done, Cancelled } = ETransactionStatus;
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [transactionStatus, setTransactionStatus] = useState<ETransactionStatus>(Null);
  const [orderId, setOrderId] = useState<string | null>(null);
  
  const [ searchParams ] = useSearchParams();
  const transactionId = searchParams.get('id');

  const getTransaction = async (data: IGetTransaction) => {
    const res = await axios
    .post(`${Config.zellshipApiUrl}/transaction`, data);

    return res.data;
  };

  const validateTransaction = async (transactionId: string) => {
    try {
      const res = await getTransaction({
        paymentServiceCode: EPaymentService.Openpay,
        transactionId,
        warehouseId: whId,
      });

      if (res.status === 'completed' || res.status === 'in_progress') {
        setTransactionStatus(Done);
        dispatch(cartActions.deleteCart());
        setOrderId(res.orderId);
        return;
      }
      
      setTransactionStatus(Cancelled);
    } catch (error) {
      console.error(error);
      setTransactionStatus(Cancelled);
    }
  };

  useEffect(() => {
    if (!transactionId) return;
    validateTransaction(transactionId);
  }, [transactionId]);
  
  return (
    <Container maxWidth="sm">
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '65vh',
        flexDirection: 'column',
      }}>
        {
          transactionStatus === Null ? (
            <>
              <Box sx={{ maxHeight: '100%' }}>
                <CircularProgress size={60}/>
              </Box>
              <Box sx={{ maxHeight: '100%' }}>
                <Typography variant="h5">
                  Validando transacción
                </Typography>
              </Box>
            </>
          ) : null
        }
        {
        transactionStatus === Cancelled ? (
          <Box sx={{ maxHeight: '100%' }}>
            <Box sx={{ 
              display: 'flex',
              gridGap: '15px',
              textAlign: 'center',
              flexDirection: 'column', 
            }}>
              <span>
                <ErrorIcon sx={{ 
                  color: red[500],
                  fontSize: '5rem', 
                }}  />
              </span>
              <Typography variant="h5">
                Lo sentimos, pero no podemos completar su compra en este momento
              </Typography>
              <Typography>
                Regrese al sitio web del comercio y seleccione otra forma de pago.
              </Typography>
              <Button 
                className="button-black"
                onClick={() => navigate('/')}
              >
                Ir al inicio
              </Button>
            </Box>
          </Box>
        ) : null
      }
      {
        transactionStatus === Done ? (
          <Box sx={{ maxHeight: '100%' }}>
            <Box sx={{ 
              display: 'flex',
              gridGap: '15px',
              textAlign: 'center', 
              flexDirection: 'column', 
            }}>
              <span>
                <CheckCircleIcon sx={{ 
                  color: green[500],
                  fontSize: '5rem', 
                }}  />
              </span>
              <Typography variant="h5">
                ¡Felicidades, el pago fue exitoso!
              </Typography>
              <Typography>
                Ingrese a su orden para ver los detalles de su compra.
              </Typography>
              <Button 
                className="button-black"
                onClick={() => navigate(`/pays/${orderId}`)}
              >
                Ir a la orden
              </Button>
            </Box>
          </Box>
        ) : null
      }
      </Box>
    </Container>
  );
};

export default TransactionStatusPage;
