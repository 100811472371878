import React from "react";
import {
  Route,
  Routes,
} from "react-router-dom";

import HomeContainer from "../modules/HomeContainer";
import AccessContainer from "../modules/AccessContainer";
import ScanContainer from "../modules/ScanContainer";
import LoginContainer from "../modules/LoginContainer";
import CartContainer from "../modules/CartContainer";
import TermsContainer from "../modules/TermsContainer";

import IronMemberContainer from "../modules/IronMemberContainer";
import StoneMemberContainer from "../modules/StoneMemberContainer";
import WoodMemberContainer from "../modules/WoodMemberContainer";
import ThanksContainer from "../modules/ThanksContainer";
import OrdersContainer from "../modules/OrdersContainer";
import OrderDetailsContainer from "../modules/OrderDetailsContainer";
import AccountContainer from '../modules/AccountContainer';
import PrivateRoute from "../components/PrivateRoute";
import TransactionStatusPage from "../modules/TransactionStatusPage";

const RoutesContainer = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeContainer />} />
      <Route path="/cart" element={
          <PrivateRoute>
            <CartContainer />
          </PrivateRoute>
        } 
      />
      <Route path="/access" element={
          <PrivateRoute>
            <AccessContainer />
          </PrivateRoute>
        } 
      />
      <Route path="/account" element={
          <PrivateRoute>
            <AccountContainer />
          </PrivateRoute>
        } 
      />
      <Route path="/scan" element={
          <PrivateRoute>
            <ScanContainer />
          </PrivateRoute>
        } 
      />

      <Route path="/login" element={<LoginContainer />} />
      <Route path="/terms" element={<TermsContainer />} />
      <Route path="/iron" element={<IronMemberContainer />} />
      <Route path="/stone" element={<StoneMemberContainer />} />
      <Route path="/wood" element={<WoodMemberContainer />} />
      <Route path='/transaction_status' element={
          <PrivateRoute>
            <TransactionStatusPage />
          </PrivateRoute>
        } 
      />
      
      <Route path="/thanks" element={
          <PrivateRoute>
            <ThanksContainer />
          </PrivateRoute>
        } 
      />
      <Route path="/pays" element={
          <PrivateRoute>
            <OrdersContainer />
          </PrivateRoute>
        } 
      />
      <Route path="/pays/:orderId" element={
          <PrivateRoute>
            <OrderDetailsContainer />
          </PrivateRoute>
        } 
      />
    </Routes>

  );
};

export default RoutesContainer;
