// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from "react";
import { 
    Box, 
    Stack, 
    Button, 
    Container, 
    TextField, 
    Typography, 
    // FormControl, 
    // InputLabel, 
    // Select, 
    // MenuItem, 
    // Checkbox, 
    // FormControlLabel, 
} from '@mui/material';
import Videos from "../components/Videos";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Config from "../config";
import userActions from "../redux/user/actions";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { QrReader } from 'react-qr-reader';

const INIT = {
    email: '',
    password: '',
    checked: false,
}
const LoginContainer = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const QRref = React.useRef(null);

    const user = useSelector((state: any) => state.user);
    
    const [data, setData] = React.useState(INIT)
    const [delayScan, setDelayScan] = React.useState(1000);
    const [permission, setPermission] = React.useState(true);
    const [openCamara, setOpenCamara] = React.useState(false);

    const disabled = (!data.password || !data.email)


    navigator.permissions.query({ name: 'camera' })
        .then((permission) => {
            console.log({ permission });
            if (permission.state !== 'granted') {
                setPermission(false);
                return;
            }
            setPermission(true);
        });

    React.useEffect(() => {
        if (user) {
            navigate('/cart')
        }
    }, []);

    const handleSubmit = (e: any) => {
        axios
            .post(`${Config.apiUrl}/login`, data, {
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                },
            })
            .then((response: any) => {
                if (!response || !response.status) {
                    console.error('Error en el response >> ');
                    console.error(response)
                    return null;
                }
                if (response.status === 202) {
                    // console.log(response.data);
                    dispatch(userActions.updateUser(response.data));
                    navigate('/cart');
                }
            })
            .catch((error) => {
                enqueueSnackbar("¡Las credenciales son incorrectas!");
                console.error(error)
            })
    }

    const handleCloseCam = () => {
        setOpenCamara(false);
        setDelayScan(false);
    };

    return (
        <Container maxWidth="sm">

            <Typography className="title" align="center" >Bienvenido</Typography>

            <Typography className="subtitle" align="center" >Ingresa a tu cuenta</Typography>
            <Box
                className="form"
                component="form">
                <Box ref={QRref}>
                    <QrReader
                        constraints={{
                            facingMode: 'environment',
                        }}
                        facingMode={'environment'}
                        resolution={600}
                        delay={5000}
                        scanDelay={delayScan}
                        videoId={'videoId'}
                        showViewFinder
                        onResult={(result, error) => {
                            if (!result) return;
                            const value = result.getText();
                            // console.log({ value });
                            setData(pervData => ({ ...pervData, email: value }))
                        }}
                        containerStyle={{
                            display: permission && openCamara ? 'block' : 'none',
                        }}
                    />
                </Box>
                {openCamara ? (
                    <Stack direction='row' justifyContent='center' spacing={1}>
                        <Button
                            variant='contained'
                            className='button-red'
                            onClick={() => {
                                handleCloseCam();
                            }}
                        >
                            Cancelar
                        </Button>
                    </Stack>
                ) : null}
                <TextField label="Correo" type="email" value={data.email} variant="outlined" onChange={(e) => {
                    setData({ ...data, email: e.target.value })
                }} />
                <TextField label="Contraseña" type="password" value={data.password} variant="outlined"
                    onChange={(e) => {
                        setData({ ...data, password: e.target.value })
                    }}
                />
                {/* 
                    TODO: ask user if want to close session after purchase
                    <FormControlLabel control={
                    <Checkbox 
                        onChange={(e) => {
                            const checked = e.target.checked;
                            console.log({ checked });
                            setData({ ...data, checked })
                        }}
                    />
                } label="Cerrar sesión al terminar la compra" /> */}
                <a href="https://calendly.com/membresias-g12/info-session">Registrate aquí</a>
                <Stack
                    direction="column"
                    justifyContent='center'
                    spacing={2}
                    sx={{ marginTop: '10px' }}
                >
                    <Button className="button-black"
                        disabled={disabled}
                        onClick={(e: any) => handleSubmit(e)}
                    >
                        Iniciar sesión
                    </Button>
                    {!openCamara ? (
                        <Stack direction='column'>
                            <Button
                                className='button-black'
                                variant='contained'
                                onClick={() => {
                                    setOpenCamara(true);
                                    setDelayScan(false);
                                }}
                            >
                                Escanear QR
                            </Button>
                        </Stack>
                    ) : null}
                </Stack>
            </Box>

            <Videos />
        </Container >
    );
};

export default LoginContainer;