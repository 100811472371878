// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  Container
} from "@mui/material";
import srcKey from "../images/key.png";
import Videos from "../components/Videos";
import { QrReader } from "react-qr-reader";
import srcBeep from "../audio/beep.mp3";
import ReactAudioPlayer from "react-audio-player";
import axios from "axios";
import Config from "../config";
import cartActions from "../redux/cart/actions";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

const ScanContainer = () => {
  const [data, setData] = React.useState("");
  const [permission, setPermission] = React.useState(false);
  const [openCamara, setOpenCamara] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const QRref = React.useRef(null);
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    navigator.permissions
      .query({ name: "camera" })
      .then((permission) => {
        setPermission(true);
      })
      .catch((error) => {
        setPermission(false);
      });
  }, []);

  React.useEffect(() => {
    if (data.length) {
      handleCloseCam();
      //aqui hacer lo del scan
    }
  }, [data]);

  const handleCloseCam = () => {
    setOpenCamara(false);
    setData("");
    navigator.getUserMedia(
      { audio: false, video: true },
      function (stream) {
        var track = stream.getTracks()[0]; // if only one media track
        track.enabled = false;
        track.stop();
      },
      function (error) {
        console.log("getUserMedia() error", error);
      }
    );
  };

  return (
    <Container maxWidth="sm">
      <Box>
        <Typography className="title" align="center">
          Ingresa la clave
        </Typography>

        <Stack
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{ marginTop: "10px" }}
        >
          {!openCamara ? (
            <Stack direction="column">
              <Stack direction="row" justifyContent="center" spacing={2}>
                <img src={srcKey} width="100%" />
              </Stack>

              <Typography align="center" className="paragraph">
                Escanea el código de la clave
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  setOpenCamara(true);
                }}
              >
                Escanear código
              </Button>
            </Stack>
          ) : null}
        </Stack>

        <Box ref={QRref}>
          <QrReader
          // constraints={{
            //facingMode: "environment",
          //}}
           facingMode={"environment"}
            resolution={600}
            delay={openCamara ? 5000 : false}
            videoId={"videoId"}
            showViewFinder
            onResult={(result, error) => {
              if (!!result) {
                console.log('bien')
                setData(result?.text);
              }
            }}
            containerStyle={{
              display: permission && openCamara ? "block" : "none",
            }}
          />
        </Box>

        {openCamara ? (
          <Stack direction="row" justifyContent="center" spacing={2}>
            <Button
              variant="contained"
              onClick={() => {
                handleCloseCam();
              }}
            >
              Cancelar
            </Button>
          </Stack>
        ) : null}

        <ReactAudioPlayer
          style={{ display: "none" }}
          ref={inputRef}
          src={srcBeep}
          controls
        />

        <Typography className="paragraph" align="center">
          {data}
        </Typography>
      </Box>

      <Videos />
    </Container>
  );
};

export default ScanContainer;
