import React, { useState } from "react";
import RoutesContainer from "./RoutesContainer";
import {
  Toolbar,
  IconButton,
  Typography,
  AppBar,
  InputBase,
  Box,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Link } from "react-router-dom";
import MenuContainer from "./MenuContainer";
import ImageG12White from "../images/g12-white.png";
import ImageGradoDoceWhite from "../images/grado-doce-white.png";
import srcImageBlack from "../images/black.png";
import { useDispatch, useSelector } from "react-redux";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

type Anchor = "top" | "left" | "bottom" | "right";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const LayoutContainer = () => {
  const user = useSelector((state: any) => state.user);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event &&
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };


  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <MenuContainer />
    </Box>
  );
  return (
    <>

      <Box className="logo-main" component='section' >
        <img width="100px" src={srcImageBlack} />
      </Box>

      <AppBar
        sx={{ marginBottom: "20px", backgroundColor: "black", position: 'relative' }}

      >

        <Toolbar sx={{ maxWidth: '1200px', margin: 'auto', display: 'flex', flexDirection: 'column', width: '100%' }}>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', margin: '10px 0px' }}  >
            <Link to="/" style={{ color: "white", textDecoration: "none" }}>
              <img width="40px" src={ImageG12White} />
            </Link>

            <Link to="/" style={{ color: "white", textDecoration: "none" }}>
              <img width="150px" src={ImageGradoDoceWhite} />
            </Link>

            {user ?
              <Link to="/cart" className="link-user" >
                {user.name}
              </Link> :
              <div className="button-signup"></div>
              // <Link to="/register" className="button-signup">
              //   Signup
              // </Link>
            }

          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', margin: '10px 0px' }}  >
            <IconButton
              onClick={toggleDrawer("left", true)}
              edge="start"
              color="inherit"
              aria-label="menu"
              className="button-bars"

            >
              <MenuIcon />
            </IconButton>

            {/* <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search> */}

            {user ?
              <Link to="/cart" className="link-cart" >
                <ShoppingCartIcon fontSize="large" />
              </Link> :
              <Link to="/login" className="button-login" >
                Login
              </Link>
            }

          </Box>

        </Toolbar>
        <div>
          <SwipeableDrawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
          >
            {list("left")}
          </SwipeableDrawer>
        </div>
      </AppBar>

      <RoutesContainer />

      <Typography align="center" sx={{ margin: "10px" }} component="footer">
        Grill on Box 2022 | Todos los derechos reservados
      </Typography>

    </>
  );
};
export default LayoutContainer;