import React from "react";
import { Typography, Stack, Box, Button, Container, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';


const TermsContainer = () => {
    return (
        <Container maxWidth="sm">
            <Typography className="title">Terminos y condiciones</Typography>

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { margin: '10px 0px', width: '100%' },
                }}
                noValidate
                autoComplete="off"
            >
                <Typography style={{marginBottom:'10px'}}>
                    Grill on Box se compromete a entregar a sus clientes los productos debidamente empacados y
                    en conservación adecuada. Por lo que cualquier producto que al recibirlo no se encuentre en
                    dichas condiciones, el cliente podrá y deberá comunicarlo por escrito, vía correo electrónico, a
                    quiero@grillonbox.com con la evidencia comprobatoria, como foto y/o video.
                </Typography>

                <Typography style={{marginBottom:'10px'}} >
                    Es responsabilidad del cliente asegurarse del buen manejo y conservación de los productos,
                    posterior a su recepción.
                </Typography>

                <Typography style={{marginBottom:'10px'}}>

                    En caso de merma, por mal manejo o conservación deficiente de productos posterior a su
                    recepción, será responsabilidad del cliente, así como determinar si el producto está o no óptimo
                    para su consumo.

                </Typography>

                <Typography style={{marginBottom:'10px'}}>

                    Se consideran criterios de garantía los siguientes:
                </Typography>
                <Box  style={{marginBottom:'10px'}}>
                <ul>
                    <ol>1. Producto incompleto o faltante</ol>
                    <ol>2. Pedido entregado fuera de tiempo</ol>
                    <ol>3. Pedido no entregado</ol>
                    <ol>4. Empaque o embalaje deficientes</ol>
                    <ol>5. Producto en mal estado</ol>
                </ul>
                </Box>

                <Typography style={{marginBottom:'10px'}}>
                    En caso de que no se encuentre nadie en el domicilio para recibir el pedido, la responsabilidad
                    será del cliente y aplicará la política "No show", en la que el costo de un segundo envío correrá
                    por cuenta del cliente; en caso de que por no poder recibir el pedido derive en un retraso o
                    entrega posterior, el cliente pierde el derecho a garantía de cualquier tipo.
                </Typography>

                <Typography style={{marginBottom:'10px'}}>
                    En caso de incidencia o reclamación de alguna índole, se requerirá de evidencia por parte del
                    cliente y tendrán una vigencia de hasta 2 hrs posteriores a la recepción de la orden (la cual se
                    validará con la paquetería o mensajería con la hora de entrega). En caso de que no se cuente
                    con estos criterios, el cliente no podrá hacer válida ninguna garantía
                </Typography>

            </Box>
        </Container >
    );
};

export default TermsContainer;