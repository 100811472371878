import actionTypes from './types';


const userReducer = (state = null, action) => {
  switch(action.type) {
    case actionTypes.UPDATE_USER: {
      const newState = action.item;
      return newState
    }
    case actionTypes.SET_PIN_USER: {

      const newState = {...state};
      newState.pin = action.item
      return newState
    }
    case actionTypes.DELETE_USER: {
        const newState = null;
        return newState
      }
    default:
      return state;
  }
}

export default userReducer;
