import React from "react";
import { Typography, Container, Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ThanksContainer = () => {
    const navigate = useNavigate();

    return (
        <Container maxWidth="sm">
            <Stack direction="column" spacing={4}>
                <Typography variant="h5" align="center" >Estamos procesando tu compra</Typography>
                <Typography variant="h2"  align="center" >Gracias por tu compra</Typography>
                <Stack direction="row" alignItems="center">
                    <Button
                        variant='contained'
                        className='button-black'
                        onClick={() => {
                            navigate('/');
                        }}
                    >
                        Regresar al menú
                    </Button>
                </Stack>
            </Stack>
        </Container >
    );
};

export default ThanksContainer;