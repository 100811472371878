import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';

interface IPrivateRoute {
  children: ReactElement;
}

const PrivateRoute = ({
  children,
}: IPrivateRoute) => {
  const auth = useSelector((state: any) => state.user);

  if (!auth) return <Navigate to='/login' replace />

  return children;
}

export default PrivateRoute;
