import React from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  Container,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
//import srcQr from "../images/qr.png"
import axios from "axios";
import Videos from "../components/Videos";
import QRCode from "react-qr-code";
import Config from "../config";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import userActions from "../redux/user/actions";

const AccessContainer = () => {

  const [code, setCode] = React.useState('')
  const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSubmit = (e: any) => {
    const qrRequest = {
      customer_id: user?.id,
      accessToken: user?.accessToken,
      warehouseCode: Config.apiWarehouseCode,
    }
    // console.log({ qrRequest });
    axios
      .post(`${Config.apiUrl}/qr`, qrRequest, { 
        headers: { 
          "Authorization": `Bearer ${user.accessToken}`
        } 
      })
      .then((response: any) => {
        if (response.status === 200) {
          setCode(response.data.pin)
          dispatch(userActions.setPinUser(response.data.pin));
          enqueueSnackbar("Se ha generado el código QR exitosamente");
        } else {
          console.error('error qr request >> ', response)
          dispatch(userActions.deleteUser());
        }
      })
      .catch((error) => {
        enqueueSnackbar("No tienes autorizado el ingreso a G12. Por favor contáctanos.");
        console.error(error)
      })
  }

  return (
    <Container maxWidth="sm">
      <Box>
        <Typography className="title" align="center">
          Ingresa a la cava
        </Typography>


        <Box className="buttons" sx={{ margin: '50px 0px' }}>
          <Button 
            className="button-black"
            onClick={(e: any) => handleSubmit(e)}
          >
            Generar QR de acceso
          </Button>
        </Box>


        {code ?
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "225px" }}
              value={code}
              viewBox={`0 0 256 256`}
            /> 
          </div>          
          : null
        }

      </Box>

      <Videos />
    </Container>
  );
};

export default AccessContainer;
