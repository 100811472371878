import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Config from "../config";
import moment from "moment";
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { 
  Box, 
  Stack, 
  Select, 
  MenuItem, 
  Container, 
  Typography, 
  InputLabel, 
  FormControl,
  SelectChangeEvent,
  Grid, 
} from '@mui/material';
import { IOrder } from '../interfaces/IOrder';
import { IPagination } from '../interfaces/IPagination';
import { ELabelDate } from '../enums/ELabelDate';
import OrderItem from '../components/OrderItem';
import Loading from '../components/Loading';

const OrdersContainer = () => {
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [selectedDate, setSelectedDate] = useState<ELabelDate>(ELabelDate.Last30Days);
  const [loading, setLoading] = useState<boolean>(false);
  
  const auth = useSelector((state: any) => state.user);
  // console.log({ auth });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { 
    AllDates,
    Last30Days,
    Last3Months,
    Last1Year,
    Last2Year,
    Last3Year,
  } = ELabelDate;

  const onChangeDate = async (event: SelectChangeEvent<ELabelDate>) => {
    const labelDate: ELabelDate = event.target.value as ELabelDate;
    setSelectedDate(labelDate);
    const startOfDateRange = labelDateToDate(labelDate);
    const endOfDateRange = moment().endOf('day');

    if (!startOfDateRange) {
      await getOrders();
      return;
    }

    await getOrders({
      startOfDateRange,
      endOfDateRange,
    });
  };

  const labelDateToDate = (labelDate: ELabelDate) => {
    if (labelDate === AllDates) return null;

    let date = moment();

    if (labelDate === Last30Days) {
      date.subtract(30, 'days');
    }
    
    if (labelDate === Last3Months) {
      date.subtract(3, 'months');
      date.startOf('month');
    }

    if (labelDate === Last1Year) {
      date.subtract(1, 'years');
      date.startOf('year');
    }

    return date.startOf('day').toString();
  };

  const getOrders = async (params?: any) => {
    try {
      setLoading(true);
      const { data: fetchOrders} = await axios.get<IPagination<IOrder>>(`${Config.zellshipApiUrl}/orders`, {
        params: {
          customerId: auth.id,
          salesOriginCode: 'autoservice',
          $skip: 0,
          $limit: 100,
          ...params,
        },
      });

      setOrders(fetchOrders.data);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("¡Ha ocurrido un error inesperado !");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const startOfDateRange = labelDateToDate(Last30Days);
    const endOfDateRange = moment().endOf('day').toString(); 

    getOrders({
      startOfDateRange,
      endOfDateRange,
    });
  }, []);

  return (
    <Container maxWidth="md">
      <Box>
        <Typography variant="h5">
          Mis órdenes 
        </Typography>
      </Box>
      <Box mt={2}>
        <FormControl fullWidth size="small">
          <InputLabel id="orderDateLabelId">Fecha</InputLabel>
          <Select
            labelId="orderDateLabelId"
            id="orderDateId"
            value={selectedDate}
            label="Fecha"
            onChange={onChangeDate}
          >
            <MenuItem value={Last30Days}>Últimos 30 días</MenuItem>
            <MenuItem value={Last3Months}>Últimos 3 meses</MenuItem>
            <MenuItem value={Last1Year}>Último año</MenuItem>
            <MenuItem value={AllDates}>Todas</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {
        loading ? (
          <Loading />
        ): (
          <Box mt={2}>
            {
              orders.length > 0 ? (
                <Stack spacing={4}>
                  {
                    orders.map(order => (
                      <OrderItem order={order} key={order.id}/>
                    ))
                  }
                </Stack>
              ):
              (
                <Typography variant="h4" align="center">
                  Sin órdenes
                </Typography>
              )
            }
          </Box>
        )
      }
    </Container>
  );
};

export default OrdersContainer;
