import React from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  Container,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import srcImage from "../images/image.png";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";

import srcStonemembership from "../images/stonemembership.png";
import srcWoodmembership from "../images/woodmembership.png";
import srcIronmembership from "../images/ironmembership.png";



const HomeContainer = () => {
  return (
    <Container maxWidth="sm">
      <Box>
        <Typography className="title">Bienvenido</Typography>

        <Iframe
          width="100%"
          height="300"
          url="https://www.youtube.com/embed/ACoORLRc56Y"
          title="YouTube video player"
        />

        <Typography className="paragraph">
          Única, exclusiva e innovadora. Así es Grado 12, la nueva membresía de Grill On Box que
          ofrece una experiencia diferenciada, con los productos más selectos, justo para el
          parrillero más exigente.
        </Typography>

        <Typography className="paragraph">

          ¿Por qué Grado 12? Porque según la escala japonesa de la clasificación de carne, Grado
          12 es la máxima calificación que determina la calidad de un corte, de acuerdo con su
          marmoleo.
        </Typography>
        <Typography className="paragraph">

          Simplemente incomparable.
        </Typography>
        <Typography className="paragraph">
          De esta manera nombramos a nuestro club, ya que ofrece un nivel de servicio y
          distinción de otro nivel.
        </Typography>
        <Typography className="paragraph">
          ¿Imaginas contar con tu propio Grill concierge o tener tu propia cava de cortes 24/7?
        </Typography>
        <Typography className="paragraph">
          Dejar de imaginar: ¡Bienvenido a Grado 12!
        </Typography>

        {/* <Link to="/register" className="button-subscribe">
          Subcríbete y recibe el primer mes gratis
        </Link> */}
      </Box>

      {/* <Box sx={{ marginTop: "20px" }}>
        <Typography className="title">Membresías</Typography>
        <Stack
          direction="row"
          justifyContent="center"
          spacing={5}
          style={{ flexWrap: "wrap" }}
        >
          <Box component={Link} to="/wood" style={{ margin: "10px" }}>
            <img src={srcWoodmembership} width="150px" />
            <Typography variant="body1" align="center">
              Wood
            </Typography>
          </Box>

          <Box component={Link} to="/stone" style={{ margin: "10px" }}>
            <img src={srcStonemembership} width="150px" />
            <Typography variant="body1" align="center">
              Stone
            </Typography>
          </Box>

          <Box component={Link} to="/iron" style={{ margin: "10px" }}>
            <img src={srcIronmembership} width="150px" />
            <Typography variant="body1" align="center">
              Iron
            </Typography>
          </Box>
        </Stack>
      </Box> */}
    </Container >
  );
};

export default HomeContainer;
