import actionTypes from './types';

const cartActions =  {
  addCart: (item)=> ({
    type: actionTypes.ADD_CART,
    item
  }),
  deleteProduct: (id) => ({
    type: actionTypes.DELETE_PRODUCT,
    id
  }),
  deleteCart: () => ({
    type: actionTypes.DELETE_CART
  }),
}

export default cartActions