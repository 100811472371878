import React, { memo, useMemo } from 'react';
import { IOrder } from '../interfaces/IOrder';
import { Box, Card, CardContent, CardHeader, Divider, Grid, Stack, Typography } from '@mui/material';
import { IDelivery } from '../interfaces/IDelivery';
import { IDeliveryProduct } from '../interfaces/IDeliveryProduct';
import DeliveryProductItem from './DeliveryProductItem';

interface IOrderProducts {
  order: IOrder;
}

const OrderProducts = memo(({
  order,
}: IOrderProducts) => {
  const getDeliveryProducts = () => {
    return order.deliveries.reduce((
      acc: IDeliveryProduct[], 
      curr: IDelivery,
    ) => [...acc, ...curr.products], []);
  };

  const deliveryProducts: IDeliveryProduct[] = useMemo(() => getDeliveryProducts(), [order]);

  return (
    <Card>
      <CardHeader subheader={
        <Grid container>
          <Grid item>
            <Typography variant='body1' color="text.primary">
              Productos
            </Typography>
          </Grid>
        </Grid>
      }/>
      <CardContent>
        <CardContent>
          <Stack spacing={2}>
            {
              deliveryProducts.map((deliveryProduct, index) => (
                <Box
                  key={`dp=${order.id}-${deliveryProduct.product.id}`}
                >
                  <DeliveryProductItem 
                    deliveryProduct={deliveryProduct} 
                  />
                  {
                    deliveryProducts.length !== index + 1 ? (
                      <Divider light />
                    ) : null
                  }
                </Box>
              ))
            }
          </Stack>
        </CardContent>
      </CardContent>
    </Card>
  );
});

export default OrderProducts;
