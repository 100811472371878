import React, { memo } from 'react';
import { Button, CircularProgress } from '@mui/material';

interface LoadingButton {
  loading: boolean;
  children?: React.ReactNode;
}

const LoadingButton = memo(({
  loading = false,
  children,
  ...props
}: LoadingButton) => {
  if (!loading) return (
    <Button {...props} >
      {children}
    </Button>
  );

  return (
    <Button 
      disabled 
      {...props} 
      startIcon={<CircularProgress color="inherit" size={15} />}
    >
      {children}
    </Button>
  );
});

export default LoadingButton;
