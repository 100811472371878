import React from "react";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { Link,useNavigate } from "react-router-dom";
import QrCodeIcon from '@mui/icons-material/QrCode';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PaymentsIcon from '@mui/icons-material/Payments';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { useDispatch, useSelector } from "react-redux";
import userActions from "../redux/user/actions";
import LogoutIcon from '@mui/icons-material/Logout';

const MenuContainer = (props: any) => {

    const user = useSelector((state: any) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSignOut = () => {
        dispatch(userActions.deleteUser());
        navigate('/login');
    }

    return (
        <>
            <List>

                {
                    user && (
                        <>
                            <ListItem key={1} button component={Link} to="/access">
                                <ListItemIcon>
                                    <QrCodeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Ingresar a la cava" />
                            </ListItem>
                            <ListItem key={3} button component={Link} to="/account">
                                <ListItemIcon>
                                    <PersonIcon />
                                </ListItemIcon>
                                <ListItemText primary="Mi cuenta" />
                            </ListItem>
                            <ListItem key={3} button component={Link} to="/cart">
                                <ListItemIcon>
                                    <ShoppingCartIcon />
                                </ListItemIcon>
                                <ListItemText primary="Agregar productos" />
                            </ListItem>
                            <ListItem key={4} button component={Link} to="/pays">
                                <ListItemIcon>
                                    <PaymentsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Pagos y recibos" />
                            </ListItem>
                        </>
                    )
                }

                <ListItem key={5} button component={Link} to="/terms">
                    <ListItemIcon>
                        <TextSnippetIcon />
                    </ListItemIcon>
                    <ListItemText primary="Terminos y condiciones" />
                </ListItem>

                {   
                    user && (
                        <ListItem key={6} button onClick={handleSignOut}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Cerrar sesión" />
                        </ListItem>
                    )
                }


            </List>
        </>
    );
};

export default MenuContainer;