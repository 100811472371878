import actionTypes from './types';


const cartReducer = (state =  [] , action) => {


  switch (action.type) {
    case actionTypes.ADD_CART: {
      const newState = [...state];
      const findIndex = newState.findIndex((f) => f.id === action.item.id)
      if (findIndex >= 0) {
        const findElement = newState[findIndex]
        findElement.quantity = findElement.quantity + 1
        newState[findIndex] = findElement
        console.error(findElement)
      } else {
        newState.push({ ...action.item, quantity: 1 })
      }
      return newState
    }

    case actionTypes.DELETE_PRODUCT: {
      const { id } = action;
      const newState = [...state];
      const findIndex = newState.findIndex((f) => f.id === id)
      newState.splice(findIndex, 1);
      return newState
    }

    case actionTypes.DELETE_CART: {
      return [];
    }
    default:
      return state;
  }
}

export default cartReducer;