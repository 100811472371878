import React from "react";
import RootContainer from "./containers/RootContainer";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { configureStore } from "./redux";
import { PersistGate } from "redux-persist/integration/react";
import { AuthProvider } from './providers/AuthProvider';
const { persistor, store } = configureStore();


class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <AuthProvider>
                    <PersistGate loading={null} persistor={persistor}>
                        <SnackbarProvider maxSnack={1}>
                            <RootContainer />
                        </SnackbarProvider>
                    </PersistGate>
                </AuthProvider>
            </Provider>

        );
    }
}

export default App;
