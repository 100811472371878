import React, { 
  ReactNode, 
  useEffect, 
} from 'react';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import userActions from "../redux/user/actions";
import Config from "../config";

interface AuthContext {
  isAuthenticated: boolean;
}

const authContext = React.createContext<AuthContext>({} as AuthContext);

function useAuth(): AuthContext {
  const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  const reAuth = async () => {
    // console.log('init auth provider');
    // console.log('user', user);
    
    const accessToken = user?.accessToken;
    if (!accessToken) {
      // console.log('not auth token');
      return;
    }
    
    // console.log('re auth');

    const loginRequest = {
      accessToken,
      strategy: 'jwt',
    };

    axios
      .post(`${Config.apiUrl}/loginjwt`, loginRequest, {
        headers: {
          'Content-type': 'application/json',
          'Accept': 'application/json',
        },
      })
      .then(response => {
        // console.log('valid token', { data: response.data });
      })
      .catch(err => {
        console.error('re auth error: ', { err });
        dispatch(userActions.deleteUser());
      })
  };

  useEffect(() => {
    reAuth();
  }, []);

  return {
    isAuthenticated: true,
  };
}

interface IAuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: IAuthProviderProps): JSX.Element => {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export default function AuthConsumer() {
  return React.useContext(authContext);
}
