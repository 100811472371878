import {createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import  cartReducer  from './cart/reducer'
//import  productReducer  from './products/reducer'
import  userReducer  from './user/reducer'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


const persistConfig = {
	key: 'root',
	storage: storage,
};

const rootReducer = combineReducers({
	cart: cartReducer,
   // products:productReducer,
    user: userReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const configureStore = () => {
    let store = createStore(persistedReducer)   
    let persistor = persistStore(store)
    return { store, persistor }
}