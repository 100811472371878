export const formatDate = (
  dateString: string, 
) => {
  const date = new Date(dateString).toLocaleDateString('es-mx', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  return date;
};
