import React from "react";
import LayoutContainer from "./LayoutContainer";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from 'notistack'


export default function RootContainer() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SnackbarProvider autoHideDuration={4000}>
        <CssBaseline />
        <Toaster position="top-center" />
        <BrowserRouter>
          <LayoutContainer />
        </BrowserRouter>
      </SnackbarProvider>
    </LocalizationProvider>
  );
}
