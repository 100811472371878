enum EOpenpayResponseError {
  OpenpayInternalError = 1000,
  BadRequest = 1001,
  Unauthorized = 1002,
  UnprocessableEntity = 1003,
  ServiceUnavailable = 1004,
  NotFound = 1005,
  Conflict = 1006,
  RejectedTransaction = 1007,
  DisabledAccount = 1008,
  RequestEntityTooLarge = 1009,
  Forbidden = 1010,
  BankAccountConflict = 2001,
  CardConflict = 2002,
  CustomerExternalIdConflict = 2003,
  InvalidCardVerifyingCode = 2004,
  CardExpiredOnSave = 2005,
  MissingCardSecurityCode = 2006,
  SandboxCard = 2007,
  CardPointsUnavailable = 2008,
  InvalidCardSecurityCode = 2009,
  DeclinedCard = 3001,
  CardExpiredOnCharge = 3002,
  InsufficientCardFunds = 3003,
  StolenCard = 3004,
  FraudulentCard = 3005,
  ForbiddenOperation = 3006,
  UnsupportedCard = 3008,
  LostCard = 3009,
  RestrictedCard = 3010,
  RetainedCard = 3011,
  BankAuthorizationRequired = 3012,
  InsufficientAccountFunds = 4001
}

export const OpenpayResponseErrorLabels: Record<EOpenpayResponseError, string> = {
  [EOpenpayResponseError.OpenpayInternalError]: 'Ocurrió un error interno en el servidor de Openpay',
  [EOpenpayResponseError.BadRequest]: 'El formato de la petición no es JSON, los campos no tienen el formato correcto, o la petición no tiene campos que son requeridos.',
  [EOpenpayResponseError.Unauthorized]: 'La llamada no esta autenticada o la autenticación es incorrecta.',
  [EOpenpayResponseError.UnprocessableEntity]: 'La operación no se pudo completar por que el valor de uno o más de los parametros no es correcto.',
  [EOpenpayResponseError.ServiceUnavailable]: 'Un servicio necesario para el procesamiento de la transacción no se encuentra disponible.',
  [EOpenpayResponseError.NotFound]: 'Uno de los recursos requeridos no existe.',
  [EOpenpayResponseError.Conflict]: 'Ya existe una transacción con el mismo ID de orden.',
  [EOpenpayResponseError.RejectedTransaction]: 'La transferencia de fondos entre una cuenta de banco o tarjeta y la cuenta de Openpay no fue aceptada.',
  [EOpenpayResponseError.DisabledAccount]: 'Una de las cuentas requeridas en la petición se encuentra desactivada.',
  [EOpenpayResponseError.RequestEntityTooLarge]: 'El cuerpo de la petición es demasiado grande.',
  [EOpenpayResponseError.Forbidden]: 'Se esta utilizando la llave pública para hacer una llamada que requiere la llave privada, o bien, se esta usando la llave privada desde JavaScript.',
  [EOpenpayResponseError.BankAccountConflict]: 'La cuenta de banco con esta CLABE ya se encuentra registrada en el cliente.',
  [EOpenpayResponseError.CardConflict]: 'La tarjeta con este número ya se encuentra registrada en el cliente.',
  [EOpenpayResponseError.CustomerExternalIdConflict]: 'El cliente con este identificador externo (External ID) ya existe.',
  [EOpenpayResponseError.InvalidCardVerifyingCode]: 'El dígito verificador del número de tarjeta es inválido de acuerdo al algoritmo Luhn.',
  [EOpenpayResponseError.CardExpiredOnSave]: 'La fecha de expiración de la tarjeta es anterior a la fecha actual.',
  [EOpenpayResponseError.MissingCardSecurityCode]: 'El código de seguridad de la tarjeta (CVV2) no fue proporcionado.',
  [EOpenpayResponseError.SandboxCard]: 'El número de tarjeta es de prueba, solamente puede usarse en Sandbox.',
  [EOpenpayResponseError.CardPointsUnavailable]: 'La tarjeta consultada no es valida para puntos.',
  [EOpenpayResponseError.InvalidCardSecurityCode]: 'El código de seguridad de la tarjeta (CVV2) no no es valido.',
  [EOpenpayResponseError.DeclinedCard]: 'La tarjeta fue declinada.',
  [EOpenpayResponseError.CardExpiredOnCharge]: 'La tarjeta ha expirado.',
  [EOpenpayResponseError.InsufficientCardFunds]: 'La tarjeta no tiene fondos suficientes.',
  [EOpenpayResponseError.StolenCard]: 'La tarjeta ha sido identificada como una tarjeta robada.',
  [EOpenpayResponseError.FraudulentCard]: 'La tarjeta fue declinada.',
  [EOpenpayResponseError.ForbiddenOperation]: 'La operación no esta permitida para este cliente o esta transacción.',
  [EOpenpayResponseError.UnsupportedCard]: 'La tarjeta no es soportada en transacciones en linea.',
  [EOpenpayResponseError.LostCard]: 'La tarjeta fue declinada.',
  [EOpenpayResponseError.RestrictedCard]: 'El banco ha restringido la tarjeta.',
  [EOpenpayResponseError.RetainedCard]: 'El banco ha solicitado que la tarjeta sea retenida. Contacte al banco.',
  [EOpenpayResponseError.BankAuthorizationRequired]: 'Se requiere solicitar al banco autorización para realizar este pago.',
  [EOpenpayResponseError.InsufficientAccountFunds]: 'La cuenta de Openpay no tiene fondos suficientes.',
};

export default EOpenpayResponseError;