import React from "react";
import { Typography, Stack, Box, Button, Container, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import srcIronmembership from "../images/ironmembership.png";


const IronMemberContainer = () => {
    return (<Container maxWidth="sm">
        <Box>
            <Typography align="center" className="title">Iron</Typography>

            <img src={srcIronmembership} width="100%" />

            <Typography className="paragraph text-red">
                Si lo exclusivo te define, la mereces
            </Typography>

            <Typography className="paragraph">
                Con esta membresía, tendrás acceso ilimitado a la Cava y al Grilling Concierge, 24/7,
                es decir, en cualquier día y a cualquier hora, además de beneficios exclusivos, como
                personal shopper, recetario trimestral, grill refill y toppings de regalo en cada compra.
                Disfrutarás de personalización de productos y entregas a domicilio sin costo, y
                descuentos del 50% de descuento en servicios parrilleros; del 50% en catas y eventos
                virtuales; del 30% en cursos; y del 15% en productos Royal Reserve.
            </Typography>

            <Typography className="paragraph">
                Tendrás derecho a cinco accesos familiares adicionales y $500.00 de descuento por
                cada referido que adquiera una membresía, además de 6 cupones virtuales, 3 del 20%
                de descuento, 2 del 30%, y 1 de $500.00 de descuento, todos con Card holder (tarjeta
                de regalo física) sin costo y vigencia de un año a partir de la compra de la membresía.
            </Typography>

            <Typography className="paragraph">
                Además, podrás acceder a contenidos exclusivos, regalos de patrocinadores,
                samplings de productos, kit de recetas exclusivas, preventas, acceso a productos
                selección G12, invitación a eventos y Demo Days, y promociones en comercios
                participantes.
            </Typography>

            <Typography className="paragraph">
                Todo por un costo anual de $4,788.00.
            </Typography>

            <Typography className="paragraph">
                Recibe 10 membresías, para compartir con amigos y familiares, por $47,880.00
            </Typography>

        </Box>


    </Container>)
}

export default IronMemberContainer;
