import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Config from "../config";
import { Box, Chip, Container, Grid, Typography } from '@mui/material';
import OrderDetails from '../components/OrderDetails';
import { useNavigate, useParams } from 'react-router-dom';
import { IOrder } from '../interfaces/IOrder';
import Loading from '../components/Loading';
import { IPagination } from '../interfaces/IPagination';
import { useSnackbar } from 'notistack';
import OrderProducts from '../components/OrderProducts';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const OrderDetailsContainer = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  
  const [order, setOrder] = useState<IOrder | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getOrder = async () => {
    try {
      setLoading(true);
      const { data: fetchOrders} = await axios.get<IPagination<IOrder>>(`${Config.zellshipApiUrl}/orders`, {
        params: {
          orderId,
        },
      });

      setOrder(fetchOrders.data[0] ?? null);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("¡Ha ocurrido un error inesperado !");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrder();
  }, [])

  if (!order || loading) return (<Loading />);

  return (
    <Container maxWidth="md">
      <Grid 
        container 
        spacing={2} 
        justifyContent="space-between"
      >
        <Grid item xs={6}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <ArrowBackIcon 
                sx={{ 
                  marginTop: '7px',
                  cursor: 'pointer', 
                }} 
                onClick={() => navigate('/pays')}
              />
            </Grid>
            <Grid item>
              <Typography variant="h5">
                Mi orden
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Chip label="Entregada" color="primary" />
            </Grid>
            <Grid item>
              {`#${order.id}`} 
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={2}>
        <OrderDetails order={order}/>
      </Box>
      <Box mt={2}>
        <OrderProducts order={order} />
      </Box>
    </Container>
  );
};

export default OrderDetailsContainer;
