import React from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  Container,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import srcVideo from "../images/video.png";
import Iframe from "react-iframe";

const Videos = (props: any) => {
  return (
    <Box sx={{ marginTop: "20px" }}>
      <Typography className="title" align="center">Videos de ayuda</Typography>

      <Grid container spacing={2} justifyContent="center" style={{ marginBottom: "20px" }}>
        <Grid item xs={12}>
          <img src="/como-comprar.png" style={{ maxWidth: '100%' }} />
        </Grid>
      </Grid>

      <Stack 
        justifyContent="center"
        direction={{ xs: 'column', sm: 'column' }}
        spacing={2}
      >
        <Stack direction="column" alignItems="center">
          <Iframe
            width="100%"
            height="200"
            url="https://www.youtube.com/embed/EML--2Fqj3I"
            title="YouTube video player"
          />
        </Stack>
      </Stack>
      <Typography align="center" sx={{ margin: "10px" }}>
        Para mayores informes, contáctanos al teléfono 871-214-2694 o al correo torreon@grillonbox.com
      </Typography>
    </Box>
  );
};

export default Videos;
