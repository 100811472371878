/*eslint no-process-env: "off"*/
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  appUrl:"https://g12.grillonbox.com",
  apiUrl: "https://g12.core.grillonbox.com",
  zellshipApiUrl: "https://api.grillonbox.com",
  apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6ImFjY2VzcyJ9.eyJpYXQiOjE2Njk4NDQ4OTIsImV4cCI6MTY2OTkzMTI5MiwiYXVkIjoiaHR0cHM6Ly95b3VyZG9tYWluLmNvbSIsImlzcyI6ImZlYXRoZXJzIiwic3ViIjoiOCIsImp0aSI6IjIyNzFjZTIzLWFjMDAtNGViZi05ZGNjLTdhNjAyYjg4NmUxNiJ9.Ljn-kOVJsPB-hz9KyE7uByLNYZJcSKBCXUCsc2Selk0",
  apiWarehouseCode:"w-ae11c355-b933-41df-8a39-13090d7a9af7",
  apiWarehouseId:"1",
};
