import React from 'react';
import srcImageBlack from "../images/black.png";
import { Grid, Typography } from '@mui/material';

const Loading = () => {
  return (
    <Grid 
      mt={2}
      container
      direction="column"
      alignItems="center" 
      justifyContent="center" 
    > 
      <Grid item>
        <img  width="100px" src={srcImageBlack} />
      </Grid>
      <Grid item>
        <Typography variant="h5">
          Cargando...
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Loading;
