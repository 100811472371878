import React, { memo } from 'react';
import { Box, ButtonBase, Grid, Typography, styled } from '@mui/material';
import { IDeliveryProduct } from '../interfaces/IDeliveryProduct';
import { formatMoney } from '../helper/formatMoney';

interface IProductItem {
  deliveryProduct: IDeliveryProduct;
}

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

const DeliveryProductItem = memo(({
  deliveryProduct,
}: IProductItem) => {

  const product = deliveryProduct.product;
  const productImgUrl = product.productImgs.length > 0 ? product.productImgs[0].url : '';

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item>
          <ButtonBase sx={{ width: 128, height: 128 }}>
            <Img alt={product.nameEs} src={productImgUrl} />
          </ButtonBase>
        </Grid>
        <Grid item xs container>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Typography 
                variant="subtitle1" 
                component="div"
              >
                {product.nameEs}
              </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  Precio unitario
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  {formatMoney(deliveryProduct.price)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  Cantidad
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  {deliveryProduct.qty}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  Total
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  {formatMoney(deliveryProduct.qty * deliveryProduct.price)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});

export default DeliveryProductItem;
