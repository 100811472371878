import React, { memo, useMemo } from 'react';
import { 
  Grid, 
  Card, 
  CardHeader, 
  Typography, 
  CardContent, 
} from '@mui/material';
import { IOrder } from '../interfaces/IOrder';
import { formatMoney } from '../helper/formatMoney';
import { formatDate } from '../helper/formatDate';

interface IOrderDetails {
  order: IOrder;
}

const OrderDetails = memo(({
  order,
}: IOrderDetails) => {
  const orderAmounts = order.amounts;
  const createdDate = useMemo(() => formatDate(order.createdAt), [order]);
  
  return (
    <Card>
      <CardHeader subheader={
        <Grid container justifyContent="space-between"
        >
          <Grid item>
            <Typography variant='body1' color="text.primary">
              Detalles de la orden
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1'>
              {createdDate}
            </Typography>
          </Grid>
        </Grid>
      } />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant='body2'>
                  Produtos
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  {formatMoney(orderAmounts.subtotal - orderAmounts.shipping)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant='body2'>
                  Envío
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  {formatMoney(orderAmounts.shipping)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant='body2'>
                  Subtotal
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  {formatMoney(orderAmounts.subtotal)}
                </Typography>
              </Grid>
            </Grid>                        
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant='body2'>
                  Descuento
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  {formatMoney(orderAmounts.discounts ?? 0)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant='body2'>
                  Total
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  {formatMoney(orderAmounts.total)}
                </Typography>
              </Grid>
            </Grid>            
          </Grid>
        </Grid>
      </CardContent>  
    </Card>
  );
});

export default OrderDetails;
