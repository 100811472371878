import actionTypes from './types';

const userActions =  {
  updateUser: (item)=> ({
    type: actionTypes.UPDATE_USER,
    item
  }),
  setPinUser: (item)=> ({
    type: actionTypes.SET_PIN_USER,
    item
  }),
  deleteUser: () => ({
    type: actionTypes.DELETE_USER
  }),
}

export default userActions