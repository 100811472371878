import React from "react";
import { Typography, Stack, Box, Button, Container, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import srcStonemembership from "../images/stonemembership.png";

const StoneMemberContainer = () => {
    return (<Container maxWidth="sm">
        <Box>
            <Typography align="center" className="title">Stone</Typography>

            <img src={srcStonemembership} width="100%" />

            <Typography className="paragraph text-red">
                Si eres selectivo por naturaleza, esto es para ti
            </Typography>

            <Typography className="paragraph">
                Con esta membresía tendrás acceso 24/7 a la Cava, así como al Grilling Concierge de
                lunes a domingo, de las 9:00 a las 17:00 horas y envíos a domicilio sin costo.
            </Typography>

            <Typography className="paragraph">
                Además, recibirás atractivos descuentos: del 20% en cursos; del 10% en productos
                Royal Reserve; del 20% de descuento en servicios parrilleros; del 20% en
                catas y eventos virtuales; y del 50% en personalización de productos.
            </Typography>

            <Typography className="paragraph">

                Tendrás derecho a 3 cupones virtuales del 20% de descuento y 1 por
                $300.00 (máximo 1 por compra, no acumulables), y Card Holder (tarjeta de regalo
                física) para uno de los cupones disponibles, todos con vigencia de un año, así como 2
                accesos familiares adicionales, y un descuento de $250.00 por cada cliente referido
                que adquiera una membresía.
            </Typography>

            <Typography className="paragraph">

                Disfrutarás también de contenidos exclusivos, regalos de patrocinadores, samplings de
                productos, kit de recetas exclusivas, preventas, acceso a productos selección G12,
                acceso a eventos y Demo Days, y promociones en comercios participantes.
            </Typography>

            <Typography className="paragraph">

                Todo por un costo anual de $3,588.00.
            </Typography>

            <Typography className="paragraph">


                Recibe 10 membresías, para compartir con amigos y familiares, por $35,880.00
            </Typography>



        </Box>
    </Container >)

}
export default StoneMemberContainer;
